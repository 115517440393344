import axios from "axios";
import apiWithJwt from "../services/apiWithJwt";
import { types } from "../store/constants/types";
import { config } from "../config";

export const getMarketPrice = (token) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get("/api/prices")
      .then((response) => {
        dispatch({
          type: types.GET_MARKET_PRICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_MARKET_PRICE_FAILED,
          payload: error.response,
        });
      });
  };
};

export const getCustomer = (token, cuid) => {
  return (dispatch) => {
    // console.log("this call", cuid);
    const   apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/customers/${cuid}`)
      .then((response) => {
        // console.log("/api/customers/",response);
        dispatch({
          type: types.GET_CUSTOMER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CUSTOMER_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getCustomerAccountDetails = (token,cuid) => {
  return (dispatch) => {
    const   apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/accounts?customer_guid=${cuid}`)
      .then((response) => {
        dispatch({
          type: types.GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CUSTOMER_ACCOUNT_DETAILS_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getDepositaddress = (token) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/deposit_addresses`)
      .then((response) => {
        dispatch({
          type: types.GET_DEPOSITADDRESS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_DEPOSITADDRESS_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getDepositaddressGuid = (token,guid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/deposit_addresses/${guid}`)
      .then((response) => {
        dispatch({
          type: types.GET_DEPOSITADDRESS_GUID_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_DEPOSITADDRESS_GUID_FAILED,
          payload: error.response,
        });
      });
  };
};
export const postDepositaddress = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/deposit_addresses`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_DEPOSITADDRESS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_DEPOSITADDRESS_FAILED,
          payload: error.response,
        });
      });
  };
};
export const postQuotesAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/quotes`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_QUOTE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_QUOTE_FAILED,
          payload: error.response,
        });
      });
  };
};
export const postTransferAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/transfers`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_TRANSFER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_TRANSFER_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getWorkFlow = (token, workflow_guid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/workflows/${workflow_guid}`)
      .then((response) => {
        dispatch({
          type: types.GET_WORKFLOW_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_WORKFLOW_FAILED,
          payload: error.response,
        });
      });
  };
};

export const getWorkFlowForPayment = (token, workflow_guid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/workflows/${workflow_guid}`)
      .then((response) => {
        dispatch({
          type: types.GET_WORK_FLOW_PAYMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_WORK_FLOW_PAYMENT_FAILED,
          payload: error.response,
        });
      });
  };
};

export const getTransfers = (token) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get("/api/transfers?per_page=100")
      .then((response) => {
        dispatch({
          type: types.GET_TRANSFERS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_TRANSFERS_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getTrades = (token) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get("/api/trades?per_page=100")
      .then((response) => {
        dispatch({
          type: types.GET_TRADES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_TRADES_FAILED,
          payload: error.response,
        });
      });
  };
};
export const postWorkflowsAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/workflows`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_WORKFLOWS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_WORKFLOWS_FAILED,
          payload: error.response,
        });
      });
  };
};

export const postWorkFlowsActionForPayment = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/workflows`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_WORK_FLOW_PAYMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_WORK_FLOW_PAYMENT_FAILED,
          payload: error.response,
        });
      });
  };
};

export const postExternalBankAccountAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/external_bank_accounts`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_EXTERNAL_BANK_ACCCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
};
export const patchExternalBankAccountAction = (token, bguid,currState) => {
  const reqData = {state : currState}
  return (dispatch) => {
    console.log(bguid);
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .patch(`/api/external_bank_accounts/${bguid}`,reqData)
      .then((response) => {
        dispatch({
          type: types.PATCH_EXTERNAL_BANK_ACCCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.PATCH_EXTERNAL_BANK_ACCCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
};
export const postExternalBankWalletAccountAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/external_wallets`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getExternalBankAccountListAction = (token,cuid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/external_bank_accounts?customer_guid=${cuid}`, {
        params: {
          per_page: 100,
        },
      })
      .then((response) => {
        dispatch({
          type: types.GET_EXTERNAL_BANK_ACCCOUNT_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_EXTERNAL_BANK_ACCCOUNT_LIST_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getSingleExternalBankAccountAction = (token, buid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/external_bank_accounts/${buid}`, {
      })
      .then((response) => {
        dispatch({
          type: types.GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
};

export const pbPostExternalBankAccountAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/external_bank_accounts`, reqData)
      .then((response) => {
        dispatch({
          type: types.PB_POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.PB_POST_EXTERNAL_BANK_ACCCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
};

export const pbGetSingleExternalBankAccountAction = (token, buid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/external_bank_accounts/${buid}`, {
      })
      .then((response) => {
        dispatch({
          type: types.PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
};


export const deleteExternalBankAccountAction = (token, buid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
     .delete(`/api/external_bank_accounts/${buid}`)
     .then((response) => {
        dispatch({
          type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_SUCCESS,
          payload: response.data,
        });
      })
     .catch((error) => {
        dispatch({
          type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
}
export const deleteExternalBankWalletAccountAction = (token, buid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
     .delete(`/api/external_wallets/${buid}`)
     .then((response) => {
        dispatch({
          type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS,
          payload: response.data,
        });
      })
     .catch((error) => {
        dispatch({
          type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED,
          payload: error.response,
        });
      });
  };
}
export const getExternalBankAccountWalletListAction = (token) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/external_wallets`)
      .then((response) => {
        dispatch({
          type: types.GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_FAILED,
          payload: error.response,
        });
      });
  };
};
export const postTradesAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/trades`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_TRADES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_TRADES_FAILED,
          payload: error.response,
        });
      });
  };
};
export const getBtcHistoryData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=1&precision=8"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_BTC_PRICE_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BTC_PRICE_FAILED,
        payload: error.response,
      });
    }
  };
};
export const getEthHistoryData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=1&precision=8"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_ETH_PRICE_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_ETH_PRICE_FAILED,
        payload: error.response,
      });
    }
  };
};

export const getUsdcHistoryData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/usd-coin/market_chart?vs_currency=usd&days=1&precision=8"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_USDC_PRICE_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_USDC_PRICE_FAILED,
        payload: error.response,
      });
    }
  };
};

export const getLtcHistoryData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/litecoin/market_chart?vs_currency=usd&days=1&precision=8"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_LTC_PRICE_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_LTC_PRICE_FAILED,
        payload: error.response,
      });
    }
  };
};
export const getBchHistoryData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/bitcoin-cash/market_chart?vs_currency=usd&days=1&precision=8"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_BCH_PRICE_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BCH_PRICE_FAILED,
        payload: error.response,
      });
    }
  };
};
export const getBtcFiveYearsData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=365&precision=0"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_BTC_PRICE_FOR_FIVE_YEARS_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BTC_PRICE_FOR_FIVE_YEARS_FAILED,
        payload: error.response,
      });
    }
  };
};
export const getEthFiveYearsData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=365&precision=0"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_ETH_PRICE_FOR_FIVE_YEARS_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_ETH_PRICE_FOR_FIVE_YEARS_FAILED,
        payload: error.response,
      });
    }
  };
};

export const getUsdcFiveYearsData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/usd-coin/market_chart?vs_currency=usd&days=365&precision=0"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_USDC_PRICE_FOR_FIVE_YEARS_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_USDC_PRICE_FOR_FIVE_YEARS_FAILED,
        payload: error.response,
      });
    }
  };
};

export const getLtcFiveYearsData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/litecoin/market_chart?vs_currency=usd&days=365&precision=0"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_LTC_PRICE_FOR_FIVE_YEARS_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_LTC_PRICE_FOR_FIVE_YEARS_FAILED,
        payload: error.response,
      });
    }
  };
};
export const getBchFiveYearsData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/bitcoin-cash/market_chart?vs_currency=usd&days=365&precision=0"
      );
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_BCH_PRICE_FOR_FIVE_YEARS_SUCCESS,
        payload: pricesData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_BCH_PRICE_FOR_FIVE_YEARS_FAILED,
        payload: error.response,
      });
    }
  };
};
export const getXrpFiveYearsData = () => {
  const currentDate = new Date();
  const pastDate = new Date();
  pastDate.setFullYear(currentDate.getFullYear() - 1);

  const timeStart = pastDate.toISOString().split('T')[0] + 'T00:00:00';

  return async (dispatch) => {
    try {
      // const response = await fetch(
      //   `https://rest.coinapi.io/v1/ohlcv/BITSTAMP_SPOT_XRP_USD/history?period_id=1DAY&time_start=${timeStart}&limit=365`,
      //   {
      //     method: 'GET',
      //     headers: {
      //       'X-CoinAPI-Key': '5D5E027C-F726-4649-8B96-92672CE697CE', 
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );
      
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/ripple/market_chart?vs_currency=cad&days=365&precision=3"
      );            
      const data = await response.json();
      const pricesData = data.prices;
      dispatch({
        type: types.GET_XRP_PRICE_FOR_FIVE_YEARS_SUCCESS,
        payload: pricesData, 
      });
    } catch (error) {
      dispatch({
        type: types.GET_XRP_PRICE_FOR_FIVE_YEARS_FAILED,
        payload: error.message,
      });
    }
  };
};

export const getCryptoMarketData = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,litecoin,bitcoin-cash,usd-coin&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );
      const data = await response.json();
      const marketData = data;
      dispatch({
        type: types.GET_CRYPTO_MARKET_DATA_SUCCESS,
        payload: marketData,
      });
    } catch (error) {
      dispatch({
        type: types.GET_CRYPTO_MARKET_DATA_FAILED,
        payload: error.response,
      });
    }
  };
};

export const identityVerificationSSNNumberAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/identity_verifications`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_IDENTITY_VERIFICATION_SSN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_IDENTITY_VERIFICATION_SSN_FAILED,
          payload: error.response,
        });
      });
  };
};

export const getIdentityVerificationSSNNumberAction = (token,uid) => {
  return (dispatch) => {
    // console.log(token,uid);
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/identity_verifications/${uid}`)
      .then((response) => {
        dispatch({
          type: types.GET_IDENTITY_VERIFICATION_SSN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_IDENTITY_VERIFICATION_SSN_FAILED,
          payload: error.response,
        });
      });
  };
};

export const identityVerificationAction = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/identity_verifications`, reqData)
      .then((response) => {
        dispatch({
          type: types.POST_IDENTITY_VERIFICATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_IDENTITY_VERIFICATION_FAILED,
          payload: error.response,
        });
      });
  };
};

export const generateCustomerAccount = (token, reqData) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .post(`/api/accounts`, reqData)
      .then((response) => {
        dispatch({
          type: types.GENERATE_CUSTOMER_ACCOUNT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GENERATE_CUSTOMER_ACCOUNT_FAILED,
          payload: error.response,
        });
      });
  };
};

export const getIdentityVerificationAction = (token,uid) => {
  return (dispatch) => {
    // console.log(token,uid);
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/identity_verifications/${uid}`)
      .then((response) => {
        dispatch({
          type: types.GET_IDENTITY_VERIFICATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_IDENTITY_VERIFICATION_FAILED,
          payload: error.response,
        });
      });
  };
};

export const getIdentityVerificationList = (token,uid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(token);
    apiWithToken
      .get(`/api/identity_verifications?customer_guid=${uid}`, {
        params:{
          per_page:100
        }
      })
      .then((response) => {
        dispatch({
          type: types.GET_IDENTITY_VERIFICATION_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_IDENTITY_VERIFICATION_LIST_FAILED,
          payload: error.response,
        });
      });
  };
};

export const contactFormDetails = (emailData) => {
  return (dispatch) => {
    axios
    .post(`${config.BACKEND_API}customers/contact-form-details`, emailData)
      .then((response) => {
        dispatch({
          type: types.POST_CONTACT_FORM_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.POST_CONTACT_FORM_DATA_FAILED,
          payload: error.response,
        });
      });
  };
};

export const resetGetIdentityVerificationList = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_IDENTITY_VERIFICATION_LIST_SUCCESS, payload: [] });
  };

};

export const resetGetIdentityVerificationListFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_IDENTITY_VERIFICATION_LIST_FAILED, payload: {} });
  };
};


export const commonError = (error) => {
  return (dispatch) => {
    dispatch({type : types.COMMON_ERROR_MESSAGE, payload : error})
  }
}
export const resetCommonError = () => {
  return (dispatch) => {
    dispatch({type : types.COMMON_ERROR_MESSAGE, payload : ''})
  }
}
export const commonSuccess = (error) => {
  return (dispatch) => {
    dispatch({type : types.COMMON_SUCCESS_MESSAGE, payload : error})
  }
}
export const resetCommonSuccess = () => {
  return (dispatch) => {
    dispatch({type : types.COMMON_SUCCESS_MESSAGE, payload : ''})
  }
}

export const resetGetIdentityVerificationSSNNumberAction = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_IDENTITY_VERIFICATION_SSN_SUCCESS, payload: [] });
  };
};
export const resetPostIdentityVerificationSSNNumberAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_IDENTITY_VERIFICATION_SSN_SUCCESS, payload: [] });
  };
};

export const resetGetIdentityVerificationAction = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_IDENTITY_VERIFICATION_SUCCESS, payload: [] });
  };
};
export const resetIdentityVerificationAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_IDENTITY_VERIFICATION_SUCCESS, payload: [] });
  };
};
export const resetMarketPrice = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_MARKET_PRICE_SUCCESS, payload: [] });
  };
};
export const resetMarketPriceFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_MARKET_PRICE_FAILED, payload: [] });
  };
};
export const resetGetCustomer = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_SUCCESS, payload: [] });
  };
};
export const resetGetCustomerAccountDetails = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS, payload: [] });
  };
};
export const resetGenerateAccountData = () => {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_CUSTOMER_ACCOUNT_SUCCESS, payload: {} });
  };
};

export const resetBtcHistoryData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_BTC_PRICE_SUCCESS, payload: [] });
  };
};
export const resetEthHistoryData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_ETH_PRICE_SUCCESS, payload: [] });
  };
};
export const resetUsdcHistoryData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_USDC_PRICE_SUCCESS, payload: [] });
  };
};
export const resetLtcHistoryData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_LTC_PRICE_SUCCESS, payload: [] });
  };
};
export const resetBchHistoryData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_BCH_PRICE_SUCCESS, payload: [] });
  };
};

export const resetBtcFiveYearsData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_BTC_PRICE_FOR_FIVE_YEARS_SUCCESS, payload: [] });
  };
};
export const resetEthFiveYearsData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_ETH_PRICE_FOR_FIVE_YEARS_SUCCESS, payload: [] });
  };
};
export const resetUsdcFiveYearsData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_USDC_PRICE_FOR_FIVE_YEARS_SUCCESS, payload: [] });
  };
};
export const resetLtcFiveYearsData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_LTC_PRICE_FOR_FIVE_YEARS_SUCCESS, payload: [] });
  };
};

export const resetBchFiveYearsData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_BCH_PRICE_FOR_FIVE_YEARS_SUCCESS, payload: [] });
  };
};

export const resetXrpFiveYearsData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_XRP_PRICE_FOR_FIVE_YEARS_SUCCESS, payload: [] });
  };
};

export const resetCryptoMarketData = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_CRYPTO_MARKET_DATA_SUCCESS, payload: [] });
  };
};

export const resetGetWorkFlow = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_WORKFLOW_SUCCESS, payload: {} });
  };
};
export const resetGetWorkFlowForPayment = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_WORK_FLOW_PAYMENT_SUCCESS, payload: {} });
  };
};
export const resetGetTransfers = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_TRANSFERS_SUCCESS, payload: {} });
  };
};
export const resetGetTrades = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_TRADES_SUCCESS, payload: {} });
  };
};
export const resetPostTransferAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_TRANSFER_SUCCESS, payload: {} });
  };
};
export const resetPostWorkflowsAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_WORKFLOWS_SUCCESS, payload: {} });
  };
};
export const resetPostWorkflowsActionForPayment = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_WORK_FLOW_PAYMENT_SUCCESS, payload: {} });
  };
};
export const resetPostExternalBankAccountAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS, payload: {} });
  };
};
export const resetPatchExternalBankAccountAction = () => {
  return (dispatch) => {
    dispatch({ type: types.PATCH_EXTERNAL_BANK_ACCCOUNT_SUCCESS, payload: {} });
  };
};
export const resetPostExternalBankAccountWalletAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS, payload: {} });
  };
};
export const resetGetExternalBankAccountListAction = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_EXTERNAL_BANK_ACCCOUNT_LIST_SUCCESS, payload: {} });
  };
};
export const resetGetSingleExternalBankAccountSuccess = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS, payload: {} });
  };
};
export const resetGetSingleExternalBankAccountFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED, payload: {} });
  };
};
export const pbResetGetSingleExternalBankAccountSuccess = () => {
  return (dispatch) => {
    dispatch({ type: types.PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS, payload: {} });
  };
};
export const pbResetPostExternalBankAccountActionSuccess = () => {
  return (dispatch) => {
    dispatch({ type: types.PB_POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS, payload: {} });
  };
};
export const pbResetPostExternalBankAccountActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.PB_POST_EXTERNAL_BANK_ACCCOUNT_FAILED, payload: {} });
  };
};
export const pbResetGetSingleExternalBankAccountFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED, payload: {} });
  };
};
export const resetGetExternalBankWalletAccountListAction = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_SUCCESS, payload: {} });
  };
};
export const resetPostTradesAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_TRADES_SUCCESS, payload: {} });
  };
};
export const resetPostQuotesAction = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_QUOTE_SUCCESS, payload: {} });
  };
};

export const resetpostQuoteFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_QUOTE_FAILED, payload: {} });
  };
};
export const resetpostTransferFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_TRANSFER_FAILED, payload: {} });
  };
};
export const resetpostTradeFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_TRADES_FAILED, payload: {} });
  };
};
export const resetgetTransferFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_TRANSFERS_FAILED, payload: {} });
  };
};
export const resetgetMarketPriceFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_MARKET_PRICE_FAILED, payload: {} });
  };
};
export const resetgetExternalBankAccountListFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_EXTERNAL_BANK_ACCCOUNT_LIST_FAILED, payload: {} });
  };
};
export const resetgetAccountFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_ACCOUNT_DETAILS_FAILED, payload: {} });
  };
};
export const resetGetCustomerFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_FAILED, payload: {} });
  };
};
export const resetGenerateAccountFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GENERATE_CUSTOMER_ACCOUNT_FAILED, payload: {} });
  };
};
export const resetGetWorkFlowFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_WORKFLOW_FAILED, payload: {} });
  };
};
export const resetGetWorkFlowFailedForPayment = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_WORK_FLOW_PAYMENT_FAILED, payload: {} });
  };
};
export const  resetGetTradesFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_TRADES_FAILED, payload: {} });
  };
};
export const  resetPostWorkflowsActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_WORKFLOWS_FAILED, payload: {} });
  };
};
export const resetPostWorkflowsActionFailedForPayment = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_WORK_FLOW_PAYMENT_FAILED, payload: {} });
  };
};
export const resetPostExternalBankAccountActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_EXTERNAL_BANK_ACCCOUNT_FAILED, payload: {} });
  };
};
export const resetPatchExternalBankAccountActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.PATCH_EXTERNAL_BANK_ACCCOUNT_FAILED, payload: {} });
  };
};
export const resetPostExternalBankAccountWalletActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED, payload: {} });
  };
};
export const resetPostDepositAddressActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_DEPOSITADDRESS_FAILED, payload: {} });
  };
};
export const resetGetExternalBankAccountWalletListActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_FAILED, payload: {} });
  };
};
export const  resetPostIdentityVerificationSSNNumberActionFailed= () => {
  return (dispatch) => {
    dispatch({ type: types.POST_IDENTITY_VERIFICATION_SSN_FAILED, payload: {} });
  };
};
export const resetGetIdentityVerificationSSNNumberActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_IDENTITY_VERIFICATION_SSN_FAILED, payload: {} });
  };
};
export const  resetIdentityVerificationActionFailed= () => {
  return (dispatch) => {
    dispatch({ type: types.POST_IDENTITY_VERIFICATION_FAILED, payload: {} });
  };
};
export const resetGetIdentityVerificationActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_IDENTITY_VERIFICATION_FAILED, payload: {} });
  };
};
export const resetDeleteExternalBankAccountAction = () => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_SUCCESS, payload: {} });
  };
};
export const resetDeleteExternalBankAccountActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_FAILED, payload: {} });
  };
};
export const resetDeleteExternalBankWalletAccountAction = () => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS, payload: {} });
  };
};
export const resetDeleteExternalBankWalletAccountActionFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED, payload: {} });
  };
};
export const resetPostContactFormData = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_CONTACT_FORM_DATA_SUCCESS, payload: {} });
  };
};
export const resetPostContactFormDataFailed = () => {
  return (dispatch) => {
    dispatch({ type: types.POST_CONTACT_FORM_DATA_FAILED, payload: {} });
  };
};